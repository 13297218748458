/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "wwcookingchallengeAPI",
            "endpoint": "https://0b82wgdu82.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:e14d4063-f9bf-465a-82f7-4a9d96af14bc",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_JyItXtY6F",
    "aws_user_pools_web_client_id": "61js7geh48jeeg8dqb6gemluvj",
    "oauth": {
        "domain": "wwcookingchallengee3ad9653-e3ad9653-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://www.wwcookingchallenge.com/,https://wwcookingchallenge.com/",
        "redirectSignOut": "http://localhost:3000/,https://www.wwcookingchallenge.com/,https://wwcookingchallenge.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "wwcookingchallengeCountryDescDDB-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "wwcookingchallengeCurrentCountryDDB-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "wwcookingchallengeDDB-dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
